import { Button, Text } from "@chakra-ui/react";
import { useEffect } from "react";
import { BsChatFill } from "react-icons/bs";

export default function KakaoShareButton({
  isLoading,
  id,
  eventName,
}) {
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const { Kakao } = window;
  useEffect(() => {
    Kakao.cleanup();
    Kakao.init("3f40ec66d943790bd10be0e3249a1f8f");
  }, []);

  return (
    <>
      {isMobile ? (
        <Button
          leftIcon={<BsChatFill />}
          backgroundColor="#FEE500"
          fontFamily="PRT-Light"
          fontSize="14px"
          _hover={{ backgroundColor: "#D8C300" }}
          _focus={{ boxShadow: "none", backgroundColor: "#A09000" }}
          isDisabled={isLoading}
          onClick={() => {
            Kakao.Share.sendCustom({
              templateId: 110315,
              templateArgs: {
                id,
                eventName,
              },
            });
          }}
        >
          <Text opacity="0.85">카톡 공유</Text>
        </Button>
      ) : null}
    </>
  );
}
