import { HStack, Text, VStack } from "@chakra-ui/react";
import { useState } from "react";
import { formatTime } from "../../utils";
import Select from "./Select";

function getTimeArray(startTime, endTime) {
  return Array.from(
    { length: endTime - startTime + 1 },
    (_, i) => i + startTime
  );
}

export default function SelectTime({
  startTime,
  onChangeStartTime,
  endTime,
  onChangeEndTime,
}) {
  const startTimeArray = getTimeArray(0, 23);
  const [endTimeArray, setEndTimeArray] = useState(getTimeArray(startTime, 24));

  const handleSelectStartTime = (time) => {
    onChangeStartTime(time);
    setEndTimeArray(getTimeArray(time + 1, 24));
    if (time >= endTime) {
      onChangeEndTime(24);
    }
  };

  const handleSelectEndTime = (time) => {
    onChangeEndTime(time);
  };

  return (
    <VStack width="100%" spacing="12px">
      <HStack width="100%" spacing="0px">
        <Select
          className="w-[85%]"
          options={startTimeArray}
          value={startTime}
          represent={represent}
          onChange={handleSelectStartTime}
        />
        <Text width="15%" paddingLeft="10px" fontFamily="PRT-SemiBold">
          부터
        </Text>
      </HStack>
      <HStack width="100%" spacing="0px">
        <Select
          className="w-[85%]"
          options={endTimeArray}
          value={endTime}
          represent={represent}
          onChange={handleSelectEndTime}
        />
        <Text width="15%" paddingLeft="10px" fontFamily="PRT-SemiBold">
          까지
        </Text>
      </HStack>
    </VStack>
  );
}

const represent = (time) => `${time}시 (${formatTime(time)})`;
