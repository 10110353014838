import { useEffect, useRef, useState } from "react";

import { MdCheck, MdKeyboardArrowDown } from "react-icons/md";
import { Box, HStack, Text } from "@chakra-ui/react";

export default function Select({ options, value, represent, onChange }) {
  const DROPDOWN_MAX_HEIGHT = 200;
  const OPTION_HEIGHT = 40;

  const [isOpen, setIsOpen] = useState(false);
  const [isUpward, setIsUpward] = useState(false);

  const toggleRef = useRef(null);
  const dropdownRef = useRef(null);

  const handleToggle = () => {
    if (toggleRef.current != null) {
      const rect = toggleRef.current?.getBoundingClientRect();
      const distanceToBottom = window.innerHeight - rect.bottom;
      const dropdownHeight = Math.min(
        DROPDOWN_MAX_HEIGHT,
        OPTION_HEIGHT * options.length
      );
      setIsUpward(distanceToBottom < dropdownHeight);
    }
    setIsOpen((prev) => !prev);
  };

  const handleClickOption = (option) => {
    setIsOpen(false);
    onChange(option);
  };

  const handleClickOutside = (event) => {
    if (
      dropdownRef.current == null ||
      toggleRef.current == null ||
      dropdownRef.current?.contains(event.target) ||
      toggleRef.current?.contains(event.target)
    ) {
      return;
    }
    setIsOpen(false);
  };

  useEffect(() => {
    if (!isOpen || dropdownRef.current == null) {
      return;
    }
    const currentIndex = options.indexOf(value);
    dropdownRef.current.scrollTop = OPTION_HEIGHT * (currentIndex - 2);
  }, [isOpen, value]);

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
      document.addEventListener("touchstart", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchstart", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchstart", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <Box ref={toggleRef} position="relative" width="85%">
      <Box
        width="100%"
        cursor="pointer"
        borderRadius="6px"
        borderWidth="1px"
        paddingX="4"
        paddingY="2"
        fontFamily="PRT-Medium"
        outline="none"
        ring={isOpen ? "2" : "0"}
        _focus={{
          outline: "none",
        }}
        onClick={handleToggle}
      >
        {represent(value)}
      </Box>
      <Box
        position="absolute"
        insetY="0"
        right="0"
        display="flex"
        alignItems="center"
        pr="3"
      >
        <MdKeyboardArrowDown size="18px" />
      </Box>
      {isOpen && (
        <Box
          ref={dropdownRef}
          position="absolute"
          zIndex="10"
          marginY="2"
          maxHeight="200px"
          width="100%"
          overflowY="auto"
          borderRadius="md"
          borderWidth="1px"
          backgroundColor="white"
          boxShadow="xl"
          style={isUpward ? { bottom: "100%" } : { top: "100%" }}
        >
          {options.map((option, index) => (
            <HStack
              key={index}
              height="40px"
              cursor="pointer"
              alignItems="center"
              spacing="12px"
              paddingX="4"
              paddingY="2"
              _hover={{ backgroundColor: "gray-100" }}
              onClick={() => handleClickOption(option)}
            >
              <Text fontFamily="PRT-Medium">{represent(option)}</Text>
              {option === value && <MdCheck size="18px" />}
            </HStack>
          ))}
        </Box>
      )}
    </Box>
  );
}
